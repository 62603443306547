// import React from "react";
// import { useIdleTimer } from "react-idle-timer";
// import { Redirect, useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
// import App from "./App";

// export default function ({ children }) {
//   const history = useHistory();
//   const handleOnIdle = (event) => {
//     console.log("user is idle", event);
//     console.log("last active", getLastActiveTime());
//     history.push("/");
//     window.localStorage.removeItem("token");
//     window.localStorage.removeItem("creatturAccessToken");
//     toast.error("Session has been expired.")
//     Redirect("/");
//   };

//   const handleOnActive = (event) => {
//     console.log("user is active", event);
//     console.log("time remaining", getRemainingTime());
//   };

//   const handleOnAction = (event) => {};
//   const { getRemainingTime, getLastActiveTime } = useIdleTimer({
//     timeout: 1000 * 60 * 30,
//     onIdle: handleOnIdle,
//     onActive: handleOnActive,
//     onAction: handleOnAction,
//     debounce: 500,
//   });

//   return <div>{children}</div>;
// }

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import sessionService from "./SessionService";

export default function SessionLogout({ children }) {
  const history = useHistory();

  const [lastActiveTime, setLastActiveTime] = useState(new Date());

  // Add your excluded paths to this array
  const excludedPaths = [
    "/",
    "/verify-otp",
    "/reset-password",
    "/changePassword",
    "/forget-password",
    "/404",
  ];

  const currentPath = window.location.pathname;
  const isExcludedPath = excludedPaths.includes(currentPath);

  const handleUserActivity = () => {
    setLastActiveTime(new Date());
    sessionService.clearTimers();
    sessionService.startTimers(handleSessionTimeout, handleIdleTimeout);
  };

  const handleSessionTimeout = () => {
    if (
      !sessionService.sessionExpired &&
      !sessionService.sessionToastDisplayed
    ) {
      sessionService.clearTimers();
      sessionService.setSessionExpired(true);
      sessionService.setSessionToastDisplayed(true);
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("creatturAccessToken");
      window.localStorage.clear();
      toast.error("Session has expired.");
    }
  };
  const handleIdleTimeout = () => {
    if (!sessionService.idleExpired && !sessionService.idleToastDisplayed) {
      sessionService.clearTimers();
      sessionService.setIdleExpired(true);
      sessionService.setIdleToastDisplayed(true);
      window.localStorage.removeItem("token");
      localStorage.removeItem("id");
      window.localStorage.removeItem("creatturAccessToken");
      window.localStorage.clear();
      toast.error("Session has expired due to inactivity.");
      window.location.reload();
    }
  };

  useEffect(() => {
    sessionService.startTimers(handleSessionTimeout, handleIdleTimeout);
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);
    return () => {
      sessionService.clearTimers();
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    if (!isExcludedPath) {
      handleUserActivity();
    }
  }, [isExcludedPath]);

  if (isExcludedPath) {
    return <div>{children}</div>;
  }

  return <div>{children}</div>;
}
